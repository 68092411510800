// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `config.ts` with `config.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const SI_CONFIG = Object.freeze({
    // Select features turn on/off
    SIGNUP_FEATURE: true,
    FORGOT_PASSWORD_FEATURE: true,

    SIGN_UP_URL: 'https://sleepimage.com/sign-up/',
    FORGOT_PASSWORD_URL: '/signup/forgotPassword',
    EMAIL_SUPPORT: 'support@sleepimage.com',
    EMAIL_SALES: 'sales@sleepimage.com',

    // Default translation language
    DEFAULT_LANGUAGE: 'en',
    // Default Measurement System
    DEFAULT_MEASUREMENT_SYSTEM: 1,
    LANGUAGES: ['en', 'cn'],
    HELP_LABELS: true,

    UPLOAD_SN_CHECK: true,
    SLEEP_DATA_FORMATS_ENABLED: 3,
    SLEEP_DATA_TYPES_ENABLED: 3,

    SLEEP_REPORT: Object.freeze({
        SHOW_PATIENT_NAME: true,
        SHOW_PATIENT_DOB: true,
    }),

    DISPLAY_DATE: Object.freeze({
        TIME_ONLY: 'HH:mm:ss',
        DATE_TIME: 'MM/DD/YYYY hh:mm:ss A',
        DATE_ONLY: 'MM/DD/YYYY',
        DATE_ONLY_LUXON: 'MM/dd/yyyy',
        DATE_ONLY_SQL_LUXON: 'yyyy-MM-dd',
        SEPARATOR: '/',
        YEAR_POS: 3,
        MONTH_POS: 1,
        DAY_POS: 2,
        PATTERN: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
    }),

    PUBLIC_DOCS: Object.freeze({
    // Clinician's guide on public site
        GOTO_CLINICIANS_GUIDE:
      'http://www.sleepimage.com/getmedia/' +
      '7102476f-7236-4668-92b5-8befca0cf35f/' +
      'A_Clinicians_Guide_to_SleepImage.aspx',
        // Decision Assist Workflow
        GOTO_DECISION_ASSIST_WORKFLOW:
      'http://www.sleepimage.com/getmedia/' +
      '147310fb-c3de-49da-aaa1-ca4a5f8c1786/' +
      'Decision-Assist-for-Objective-Sleep-Screening.aspx',

        // Instructions for use on public site
        GOTO_WEBSITE_INSTRUCTIONS_PATIENT:
      'http://www.sleepimage.com/getmedia/' +
      '0da524c3-ff24-4e6a-9785-a54d04403deb/' +
      'Instructions-for-Use-Corporate-Patient-Based.aspx',

        GOTO_WEBSITE_INSTRUCTIONS_DEVICE:
      'http://www.sleepimage.com/getmedia/' +
      'a82da2f3-0b3a-4547-9923-3dce74014000/' +
      'Instructions-for-Use-Corporate-Device-Based.aspx',

        GOTO_DEVICE_INSTRUCTIONS:
      'http://www.sleepimage.com/getmedia/' +
      '35e33b99-1510-4607-a408-7f563616e618/' +
      'Instructions-for-Use-Sleep-Data-Recorder.aspx',

        // User Instruction card that comes with purchase of recorder
        GOTO_USER_INSTRUCTION_CARD:
      'http://www.sleepimage.com/getmedia/' +
      'b6ff8d46-ad57-4958-a88a-9c51a09e4727/' +
      'Performing-a-SleepImage-Study-User-Card.aspx',

        // Instruction for RTC Device Manager
        GOTO_RTC_MANAGER_INSTRUCTION:
      'http://www.sleepimage.com/getmedia/' +
      '2440b100-0eaf-48cc-a1ff-0efc5e76cadc/' +
      'Sleep-Data-Recorder-Time-Sync-Instructions.aspx',

        // Terms of use and privacy docs from public site
        GOTO_TERMS_OF_USE:
      'http://www.sleepimage.com/getmedia/' +
      'a964dfe9-e4a8-41fe-be13-5dfce0de7912/Terms-of-Use.aspx',
        GOTO_PRIVACY_POLICY:
      'http://www.sleepimage.com/getmedia/' +
      '1591eefe-80b5-4da2-b2a1-c389f584540b/Privacy-Policy.aspx',
        GOTO_PRIVACY_PRACTICES:
      'http://www.sleepimage.com/getmedia/' +
      '08292102-78b8-4294-9841-3a21d2fa5ad8/Privacy-Practices.aspx',
    }),

    PUBLIC_LINKS: Object.freeze({
    // Download link to RTC Device Manager
        GOTO_RTC_MANAGER:
      'http://www.sleepimage.com/getmedia/' +
      'c800ed7b-67ff-4a70-96dd-cd8ca874c336/' +
      'siDevMgr-Setup-1-2.aspx',

        // Link to youTube channel
        GOTO_YOUTUBE_CHANNEL:
      'https://www.youtube.com/channel/UCuf0NcS47u4T07Nf1ezE6kA',
    }),
});
