import { Injectable } from '@angular/core';
import { Observable, pipe, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { API_END_USER } from '@app/constants/apis';
import { ApiService } from '@service/api.service';
import { MembershipInfo } from
    '@app/secure/core/membership/membershipInfo.model';

@Injectable
({
    providedIn: 'root',
})
class MembershipService
{
    public membershipInfo: MembershipInfo;
    public memberType = null;

    constructor
    (
        private apiService: ApiService
    )
    {}

    queryMyMembershipInfo(): Observable<MembershipInfo>
    {
        let serviceObj;

        serviceObj = this.apiService
            .post<any>(API_END_USER.GET_MY_MEMBERSHIP_INFO, {})
            .pipe(map((result: any) =>
            {
                this.membershipInfo =
                    new MembershipInfo(result.type);

                return this.membershipInfo;
            }),
            catchError((err) =>
                throwError(err)
            )
        );

        return serviceObj;
    }

    getInfo()
    {
        return this.membershipInfo;
    }

    setInfo
    (
        membershipInfo
    )
    {
        this.membershipInfo = membershipInfo;
        this.memberType = membershipInfo.membershipType;
    }

    isPatientMembership(): boolean
    {
        if (this.membershipInfo.membershipType == 2)
        {
            return true;
        }
        else
        {
            return false;
        }
    }
}
export { MembershipService };
