import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from '@app/pageNotFound.component';

const routes: Routes = 
[
    { 
        path: '**', 
        component: PageNotFoundComponent 
    }
];

@NgModule
({
    imports: 
    [
        RouterModule.forRoot(routes, {})
    ],
    exports: 
    [
        RouterModule
    ]
})
export class AppRoutingModule {}
