// used to get refresh token if the service fails
import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptor,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { Subject, Observable, throwError } from 'rxjs';
import { switchMap, catchError, retry, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AuthService } from '@app/secure/auth/auth.service';
import { SessionService } from '@service/core/session.service';
/**
 * Intercepts the HTTP responses, and in case that an
 * error/exception is thrown, handles it
 * and extract the relevant information of it.
 */
@Injectable
({ 
    providedIn: 'root' 
})
class ErrorIntercept implements HttpInterceptor
{
    refreshTokenInProgress = false;
    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor
    (
        private injector: Injector,
        private sessionService: SessionService,
        private authService: AuthService
    )
    {
        this.refreshTokenInProgress = false;
    }

    private addAuthHeader
    (
        request
    )
    {
        let isFormData; 
        let authHeader;
        let cloneRequest;

        isFormData = request.body instanceof FormData;
        authHeader = this.authService.getAuthorizationHeader(isFormData);        
        
        if (authHeader) 
        {
            cloneRequest = request.clone
            ({
                setHeaders: authHeader
            });
            
            return cloneRequest;
        }
        
        return request;
    }

    private refreshToken(): Observable<any>
    {
        let observable;
        
        if (this.refreshTokenInProgress)
        {
            return new Observable((observer) =>
            {
                this.tokenRefreshed$.subscribe(() =>
                {
                    observer.next();
                    observer.complete();
                });
            });
        }
        else
        {
            this.refreshTokenInProgress = true;
            
            observable = this.sessionService
            // chain call for sessionService            
            .refreshToken(this.authService.getRefreshToken())
            .pipe(tap(() =>
            {
                this.refreshTokenInProgress = false;
                this.tokenRefreshedSource.next();
            }),
            catchError (err =>
            {
                this.refreshTokenInProgress = false;
                return throwError(err);
            }));
            
            return observable;
        }
    }

    private handleResponseError
    (
        errorResponse: HttpErrorResponse,
        request?: HttpRequest<any>,
        next?: HttpHandler
    )
    {
        let accessToken;
        
        if (errorResponse.status == 400)
        {
            // Show message
            console.log('application error');
        }
       
        // expired token
        else if (errorResponse.status == 401)
        {
            if (errorResponse.url == 
                    environment.apiURL + '/api/session/refresh' ||
                errorResponse.url == environment.apiURL + '/api/login/webApp')
            {
                return throwError( errorResponse.status );
            }
            else
            {
                return this.refreshToken().pipe(
                    switchMap((response) =>
                    {
                        accessToken = response.siData.accessToken;                        
                        this.authService.setToken(this.authService
                                                      .getRefreshToken(),
                                                  accessToken);
                        this.sessionService.updateAccessToken(accessToken);    
                        request = this.addAuthHeader(request);
                        return next.handle(request).pipe
                        (
                            catchError((error) =>
                            {
                                return throwError({status : 1000});
                             })
                        );
                    }),
                    catchError(e =>
                    {
                        if (e.status !== 401)
                        {
                            return this.handleResponseError(e);
                        }
                    })
                );
            }
        }

        // Access denied error
        else if (errorResponse.status == 403)
        {
            console.log('Access denied error.');
            // Show message
            // Logout
            // this.logout();
        }

        // Server error
        else if (errorResponse.status == 500)
        {
            // Show message
            console.log('Access denied error.');
        }

        // Maintenance error
        else if (errorResponse.status == 503)
        {
            // Show message
            console.log('Access denied error.');
            // Redirect to the maintenance page
        }
        else if (errorResponse.status == 0)
        {
            // Show message
            console.log('SleepImage Server is down.');
        }
        return throwError(errorResponse.status);
    }

    public intercept
    (
        request: HttpRequest<any>, 
        next: HttpHandler
    ): Observable<any>
    {
        let observable;
        
        observable = next.handle(request).pipe(
            catchError((error: HttpErrorResponse) =>
            {
                const errorMessage = '';

                return this.handleResponseError(error, request, next);
            })
        );
        
        return observable;
    }
    
    public destroy()
    {
        this.refreshTokenInProgress = false;
    }
}

export { ErrorIntercept };

/**
 * Provider Plain old Javascript Object (POJO) for the interceptor
 */
export const ErrorInterceptorProvider = 
{
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorIntercept,    
    multi: true,
};
