const PAGE_TITLE = Object.freeze
({
    CLINICIAN_LIST: 'Clinician List',
    CUSTOMER_LIST: 'Customer List',
    DISTRIBUTOR_LIST: 'Distributor List',
    PATIENT_LIST: 'Patient List',
    RECORDER_LIST: 'Recorder List',
    SI_ADMIN_LIST: 'SIAdmin List',
    REPORTS_LIST: 'Acivity Reports',
    ADMIN_PROFILE: 'Administrator Profile',
    CLINICIAN_PROFILE: 'Clinician Profile',
    SETTINGS: 'Account Settings',
    HELP: 'Help',
    SYSTEM_CONFIG: 'System Configuration',
    ADD_PATIENT: 'Add New Patient',
    EDIT_PATIENT: 'Edit Patient',
    ADD_CLINICIAN: 'Add New Clinician',
    EDIT_CLINICIAN: 'Edit Clinician',
    ADD_SI_ADMIN: 'Add New SIAdmin',
    EDIT_SI_ADMIN: 'Edit SIAdmin',
    ADD_DISTRIBUTOR: 'Add New Distributor',
    EDIT_DISTRIBUTOR: 'Edit Distributor',
    ASSIGN_CLINICIAN: 'Assign Clinician',
    FLAG_RECORDER: 'Flag Recorder',
    PATIENT_STUDY_DATA: 'Patient Data',
    UPLOAD_STUDY: 'Upload Studies'
});

// There is no need for 1, 2, 3. They go to the screen where those labels 
// are not needed
// Indexes are being used in ROLE_TYPE, so it is better to use indexes here
const ROLE_ACCOUNT_LABEL = Object.freeze
({
    //1: 'SI Admin Account',
    2: 'MAIN_ACCOUNT_LABEL.ADMIN',
    3: 'MAIN_ACCOUNT_LABEL.CLINICIAN',
    //4: 'SI Manager Account',
    //5: 'Distributor Account'
});

const MEMBERSHIP_TYPE_LABEL = Object.freeze
({
    0: '',
    1: 'Device Membership',
    2: 'Patient Membership',
    3: 'Demo Membership'
});

const DECISION_ASSIST_CATEGORY_LABEL = Object.freeze
({
    NONE: 'Insufficient Data',
    GREEN: 'No Evidence of Sleep Disorder',
    YELLOW: 'Evidence of Sleep Disorder',
    RED: 'Clear Evidence of Sleep Disorder'
});

export 
{
    PAGE_TITLE,
    ROLE_ACCOUNT_LABEL,
    MEMBERSHIP_TYPE_LABEL,
    DECISION_ASSIST_CATEGORY_LABEL
};
