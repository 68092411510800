<si-header-secure [role]="role"></si-header-secure>
<div class="page-spacer"></div>
<div class="login-info" 
     *ngIf="theEndUser.accountLabel !== null">
    <span *ngIf="theEndUser.isCustomerUser"
        translate="{{theEndUser.accountLabel}}"></span>
    <span class="glyphicon glyphicon-option-vertical"
        *ngIf="theEndUser.isCustomerUser && 
                theEndUser.customerName !==null"></span> 
    <span *ngIf="theEndUser.isCustomerUser && 
                theEndUser.customerName !=null"
        translate="MAIN_SUBSCRIPTION_LABEL.ACCOUNT">
    </span> 

    <span *ngIf="theEndUser.isCustomerUser && 
                    theEndUser.customerName!== null">
        {{theEndUser.customerName}}
    </span>


    <span class="glyphicon glyphicon-option-vertical"
        *ngIf="theEndUser.isCustomerUser && 
                    theEndUser.organizationName !==null"></span>
    <span *ngIf="theEndUser.isCustomerUser && 
                theEndUser.organizationName !==null"
        translate="MAIN_SUBSCRIPTION_LABEL.ORGANIZATION">
    </span>
    <span *ngIf="theEndUser.isCustomerUser && 
                theEndUser.organizationName !== null">
        {{theEndUser.organizationName}}
    </span>
    <span class="glyphicon glyphicon-option-vertical"
        *ngIf="theEndUser.isCustomerUser && 
                        theEndUser.expiration !== null"></span>
    <span *ngIf="theEndUser.isCustomerUser && 
                      theEndUser.expiration !== null"
          translate="MAIN_SUBSCRIPTION_LABEL.RENEWAL_DATE"></span>
    <span *ngIf="theEndUser.isCustomerUser && 
                 theEndUser.expiration !== null">
          {{theEndUser.expiration}}
    </span>

    <span class="glyphicon glyphicon-option-vertical"
          *ngIf="theEndUser.isCustomerUser && 
                      theEndUser.reportsRemaining !== null"></span>
    <span *ngIf="theEndUser.isCustomerUser && 
                      theEndUser.reportsRemaining !== null"
          translate="MAIN_SUBSCRIPTION_LABEL.REPORTS_REMAINING"></span>
    <span *ngIf="theEndUser.isCustomerUser && 
                      theEndUser.reportsRemaining !== null">
          {{theEndUser.reportsRemaining}}
    </span>  
</div>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>
<div>
	<footer id="dynamic">
	    <div id="si-footer-container"
	         [ngClass]="{'hide-footer': !show,
	                     'show-footer': show}">
	        <div id="footer-action" class="text-center">
	            <span class="glyphicon glyphicon-menu-up" 
	                    [ngClass]="{'opened': show}"
	                    (click)="show=!show">
	            </span>
	        </div>
	        <div 
	            class="footer-content"
	            [innerHTML]="footerHtml">
	        </div> <!-- footer-content -->
	    </div> <!-- #footer-container -->
	</footer>
</div>