import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/secure/auth/guards/auth.guard';
import { SecureLayoutComponent } from '@app/secure/core/layout/secureLayout.component';

/*
 * The secure layout child routes.
 *
 * All routes here SHOULD be guarded by the AuthGuard to ensure only
 * logged in users can see these.
 *
 * These are all lazy loaded depending on url based on endUser role.
 */
const routes: Routes = 
[
    // Lazy Load Customer routes
    {
        path: 'customer',
        component: SecureLayoutComponent,
        loadChildren: () =>
            import('./routingModules/customerAccess.module')
            .then((m) => m.CustomerAccessModule),
        canLoad: [AuthGuard]
    },
    {
        path: 'reports',
        component: SecureLayoutComponent,
        loadChildren: () =>
            import('./routingModules/customerAccess.module')
            .then((m) => m.CustomerAccessModule),
        canLoad: [AuthGuard],
    },
    
    // Lazy Load SI routes
    {
        path: 'si',
        component: SecureLayoutComponent,
        loadChildren: () =>
            import('./routingModules/siAccess.module')
            .then((m) => m.SIAccessModule),
        canLoad: [AuthGuard],
    },

    // Lazy Load Distributor routes
    {
        path: 'distributor',
        component: SecureLayoutComponent,
        loadChildren: () =>
            import('./routingModules/distributorAccess.module')
            .then((m) => m.DistributorAccessModule),
        canLoad: [AuthGuard],
    },
];

@NgModule
({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
class SecureRoutingModule {}

export { SecureRoutingModule }
