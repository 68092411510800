<mat-toolbar color='primary' id="header">
  <span class="logo">
    <a href="/"><h1>SleepImage</h1></a>
  </span>
  <si-navigation [role]="role"></si-navigation>  
  <span class="header-spacer"></span>   
  <a class="header-menu-item" [routerLink]="[]" (click)="signOut()">
    <span class="item-text">{{'MAIN_MENU.SIGN_OUT'|translate}}</span>
  </a>
  <a class="green-pointer" 
    *ngIf="role === ADMIN_ROLE"
    href="https://www.sleepimage.com/"
    target="_blank">
        <span class="green-pointer-text">
            {{ 'MAIN_MENU.STORE' |translate }}
        </span>
    </a>
</mat-toolbar>
