import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

import { Observable, pipe, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import moment from 'moment';
import { DATE_FORMAT_CONST } from '@app/constants/global';
import { API_END_USER } from '@app/constants/apis';
import { ApiService } from '@service/api.service';
import { Subscription } from 
    '@app/secure/core/subscription/subscription.model';
import { EndUserLocaleService } from '@service/core/endUserLocale.service';
import { EndUserRoleService } from '@service/core/endUserRole.service';
import { EndUserService } from '@service/core/endUser.service';

@Injectable
({
    providedIn: 'root'
})
class SubscriptionService 
{
    //_____________________ P R I V A T E  D A T A   ___________________________
    
    //_______________________ P U B L I C  D A T A   ___________________________
    
    public subscription: Subscription;

    //__________________________ C O N S T R U C T O R _________________________

    constructor
    (
       private apiService: ApiService,
       private endUserRoleService: EndUserRoleService,
       private endUserLocaleService: EndUserLocaleService,
       private endUserService: EndUserService
    ) 
    {} 
  
    
    //______________________ S T A T I C  M E T H O D S ________________________

    //_____________________ P R I V A T E  M E T H O D S _______________________
    
    //____________________ P U B L I C   M E T H O D S _________________________
    
    public query(): Observable<Subscription>
    {
        let role;
        let locale;
        let expirationDTLocale;
        let serviceObj;
        
        role = this.endUserService.getRole();
        if (this.endUserRoleService.isCustomerRole(role) === false)
        {
            serviceObj = of(null);    
        }
        else
        {
            serviceObj = this.apiService
            .post<any>(API_END_USER.GET_MY_SUBSCRIPTION_INFO, {})
            .pipe(map((result: any) =>
            {                
                locale = this.endUserLocaleService.getMyLocale();
                  
                if (result.expirationDT !== null)
                {
                    expirationDTLocale = moment.utc(result.expirationDT)
                                               .locale(locale);
                    expirationDTLocale = expirationDTLocale.format(
                                            DATE_FORMAT_CONST.DATE_ONLY_LOCALE);
                    result.expirationDT = expirationDTLocale;
                }
                                    
                this.subscription = new Subscription(result);
    
                return this.subscription;                
            }),
            catchError((err) => throwError(err)));
        }
        
        return serviceObj;
    }
}

export { SubscriptionService }
