const DIALOG_TYPE_CONST = 
{
    ADD_EVENT: 1,
    CHANGE_EVENT: 2,
    RECALCULATE: 3,
    RESET: 4,
    WAIT_RECALCULATE: 5,
    WAIT_RESET: 6,
    ERROR_BOX: 7,
    GENERATING_REPORT: 8,
    LOADING: 9,
    DELETE_NOTAUTHORIZED: 10,
    DELETE_STUDY_CONFIRM: 11,
    TWO_FACTOR_CANCELLATION: 12,
    REVOKE_SHARE_CONFIRM: 15
}
export { DIALOG_TYPE_CONST }