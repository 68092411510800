<div class="logo-wrapper">
  <div class="logo-main-container">
    <img class="logo img-responsive rounded mx-auto d-block"
      src="{{logo}}"
      alt="SleepImage">
  </div>
</div>
<!-- <mat-toolbar color='primary' id="header">
  <span class="logo">
    <a [href]="publicWebsiteUrl"><h1>SleepImage</h1></a>
  </span>

  <span class="header-spacer"></span>

  <a *ngIf="allowSignUp" class="header-menu-item"
    routerLink="/login" routerLinkActive="active">
    Sign In
  </a>
  <a *ngIf="allowSignUp" class="header-menu-item"
    routerLink="/signup" routerLinkActive="active">
    Sign Up
  </a>
</mat-toolbar>
<meta charset="UTF-8">
<meta name="viewport"
       content="width=device-width, initial-scale=1, maximum-scale=1">
<base href="/">
<title>SleepImage System</title>
<link rel="icon" type="image/x-icon" href="/images/favicon.ico"> -->
