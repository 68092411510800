<footer>
<div class="footer-container">
  <div class="rows">
    <p class="terms col">
      <a target="_blank" href="">Terms Of Use Agreement</a>
      <a target="_blank" href="">Privacy Policy</a>
      <a target="_blank" href="">Privacy Practices Concering Personal Health Information</a>
    </p>
  </div>
  <div class="rows">
    <p class="col udi">
      <span>UDI*+B315sleepimage.com0.*</span>
      <span class="prescription-mark">&#8478;</span>
      <span> This is a prescription product.</span>
    </p>
  </div>
  <div class="rows">
    <p class="col">
      <span>&#169;</span>
      2018 MyCardio, LLC. All rights reserved.<br>
      SleepImage
      <span>&#174;</span>
      is a registered trademark of MyCardio LLC.
    </p>
  </div>
</div>
</footer>
