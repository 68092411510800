import { Component, OnInit } from '@angular/core';

import { SI_CONFIG } from '@app_config/config';
import { environment } from '@env/environment';
import { LOGO_BIG_WHITE } from '@app/constants/images';

@Component({
    selector: 'si-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit
{
    allowSignUp?: boolean;
    publicWebsiteUrl?: string;
    logo = LOGO_BIG_WHITE;

    constructor()
    {}

    ngOnInit()
    {
        this.allowSignUp = SI_CONFIG.SIGNUP_FEATURE;
        this.publicWebsiteUrl = environment.publicWebsiteURL;
    }
}
