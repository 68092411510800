import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";

import { environment } from '@env/environment';

// TODO : MAY NEED TO MOVE IT TO SECURE MODULE,
//        EndUserService is in root and that is why
//        this service is in root
@Injectable
({
    providedIn: 'root'
})
class SessionService 
{
    constructor
    (
        private http: HttpClient            
    ) 
    { }
    
    /**
     * @desc   - refresh access token
     * @access - public
     * @param  - 
     * @return - Observable httpRequest : observable object to refresh token
     */            
    public refreshToken 
    (
        refreshToken
    ) : Observable<any>
    {
        let httpRequest;
                
        httpRequest = this.http.post<any>(
                         `${environment.apiURL}/api/session/refreshToken`,  
                                          {refreshToken});
        return httpRequest;
    }
    
    public getSession()
    {
        let sessionData;
        
        sessionData = JSON.parse(sessionStorage.getItem('session'));
        
        return sessionData;
    }
    
    public saveSession
    (
        sessionData
    )
    {
        sessionStorage.setItem('session', JSON.stringify(sessionData));     
    }
    
    public updateAccessToken
    (
         accessToken
    )
    {
        let sessionData;
        
        sessionData = JSON.parse(sessionStorage.getItem('session'));
        sessionData.accessToken = accessToken;
        sessionStorage.setItem('session', JSON.stringify(sessionData));
        
        return sessionData;
    }    
        
    public clearSession()
    {
        sessionStorage.clear(); 
    }
}

export { SessionService }