<si-header *ngIf="!loggedIn"></si-header>
<div class="login-wrapper" *ngIf="!loggedIn">
  <div class="login-main-container text-center">
    <h2 class="product-name animate-translate" translate>LOGIN.TITLE
    </h2>
    <h5 class="product-version" data-ng-cloak>
      <span translate>LOGIN.VERSION</span>
    </h5>
    <div class="row">
        <div class="alert alert-danger col-xs-12"
            *ngIf="hasLoginError">
            <span [innerHTML]=
                "'LOGIN_MSG.INVALID_USERNAME_PASSWORD' | translate">
            </span>
        </div>
    </div>
    <div class="main-center-content">
      <form [formGroup]="loginForm" 
            (ngSubmit)="submitLogin()" class="sign-in-up">
        <div class="flex-item left-item">
          <div class="form-group">
            <label for="username" translate>LOGIN.USERNAME</label>
              <input type="text" formControlName="username"
                      translate-attr="Username"
                      required>
          </div>
          <div class="form-group mt14">
            <label for="password" translate>LOGIN.PASSWORD</label>
            <input type="password" formControlName="password"
              translate-attr="Password"
              required>
          </div>
        </div>
        <div class="flex-item right-item">
          <div class="button-container">
            <button type="submit" class="btn btn-primary btn-yellow" 
                    translate>LOGIN.SIGN_IN</button>
          </div>
        </div>
        <!-- <div class="flex-item">
          {{loginMessage}}
        </div> -->
        <div class="flex-item flex-item-full-row flex-space-down">
          <div class="forgot-password"
            *ngIf="features.forgotPassword">
            <a href="" target="_self" translate>LOGIN.FORGOT_PASSWORD</a>
          </div>
        </div>
        <div class="flex-item not-signed-up left-item">
          <span translate>LOGIN.NOT_SIGNED_UP</span><sup>*</sup>
          <br>
          <span class="footnote">
            <sup>*</sup>
            <span translate>LOGIN.AVAILABLE_FOR_PROFESSIONALS</span>
          </span>
        </div>
        <div class="flex-item right-item">
          <div class="button-container">
            <a class="btn btn-default btn-grey"
              href="https://sleepimage.com/sign-up/" target="_self" 
              translate>LOGIN.SIGN_UP_HERE</a>
          </div>
        </div>
      </form>
    </div>
    <div class="main-center-content mt30"
      *ngIf = "features.supportEmail || features.salesEmail">
      <div class="question-content">
        <div class="flex-item" translate>LOGIN.QUESTIONS</div>
        <div class="flex-item">
          <a class="btn btn-white"
            href="mailto:mehran.majidi@sleepimage.com?subject=Type%20In%20Your%20Subject%20Here" 
            translate>LOGIN.CONTACT_SALES</a>
        </div>
        <div class="flex-item">
          <a class="btn btn-white"
            href="mailto:mehran.majidi@sleepimage.com?subject=Type%20In%20Your%20Subject%20Here" 
            translate>LOGIN.CONTACT_SUPPORT</a>
        </div>
      </div>
    </div>
    <div class="push-down"></div>
  </div>
  <si-footer></si-footer>
</div>
<form class="translate-wrapper" [formGroup]="languageForm">
  <select class="form-select" formControlName="language" 
        (change)="setLanguage()">
    <option value="en">English</option>
    <option value="cn">中文</option>
  </select>
</form>