<!-- 
<button class="navbar-toggle collapsed"></button>
    <div class="navbar-header">
        <button class="navbar-toggle collapsed" type="button">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar top-bar"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
       </button>       
      <a class="navbar-brand logo" 
         title="SleepImage">
        <img src="/images/sleep-image-logo-mobile-2x.png"></a>
    </div> --> <!-- .navbar-header -->
<div class="navigation-wrapper">
    <a class="header-menu-item"
        *ngFor="let item of menuItems"
        [routerLink]="item.route"
        routerLinkActive="active">
        <span class="item-text">
            {{ item.label|translate }}
        </span>
    </a>
</div>
    
