<div class="dialog-container">
   <h2 mat-dialog-title class="dialog-header">
      <span *ngIf="!errorMessage" class="dialog-title-text">{{title}}</span>
      <img *ngIf="errorMessage" [src]="errorIcon" alt="Error icon" class="error-icon"
       width="30" height="30">
      <span *ngIf="errorMessage" class="dialog-title-text red">{{title}}</span>
      <button mat-icon-button color="primary" class="left" [disabled]="noCloseBtn"
         id="closeBtnId" matTooltip="Close" (click)="closeDialog()">
         <mat-icon aria-label="Close">close</mat-icon>
      </button>
   </h2>
   <mat-dialog-content>
      <ng-container [ngSwitch]="dialogType">
<!--        
         <ng-container *ngSwitchCase="dialogTypeConst.ADD_EVENT">
            <ng-container *ngTemplateOutlet="addEvent"></ng-container>
         </ng-container>         
         <ng-container *ngSwitchCase="dialogTypeConst.CHANGE_EVENT">
            <ng-container *ngTemplateOutlet="changeEvent"></ng-container>
         </ng-container>
-->         
         <ng-container *ngSwitchCase="dialogTypeConst.RECALCULATE">
            <ng-container *ngTemplateOutlet="recalculate"></ng-container>
         </ng-container>
         <ng-container *ngSwitchCase="dialogTypeConst.RESET">
            <ng-container *ngTemplateOutlet="reset"></ng-container>
         </ng-container>
         <ng-container *ngSwitchCase="dialogTypeConst.WAIT_RECALCULATE">
            <ng-container *ngTemplateOutlet="waitRecalculate"></ng-container>
         </ng-container>
         <ng-container *ngSwitchCase="dialogTypeConst.WAIT_RESET">
            <ng-container *ngTemplateOutlet="waitReset"></ng-container>
         </ng-container>
         <ng-container *ngSwitchCase="dialogTypeConst.ERROR_BOX">
            <ng-container *ngTemplateOutlet="errorDialog"></ng-container>
         </ng-container>
         <ng-container *ngSwitchCase="dialogTypeConst.GENERATING_REPORT">
            <ng-container *ngTemplateOutlet="generatingReportDialog"></ng-container>
         </ng-container>
         <ng-container *ngSwitchCase="dialogTypeConst.LOADING">
            <ng-container *ngTemplateOutlet="loadingStatus"></ng-container>
         </ng-container>
         <ng-container *ngSwitchCase="dialogTypeConst.DELETE_NOTAUTHORIZED">
            <ng-container *ngTemplateOutlet="deleteNotAuthorized"></ng-container>
         </ng-container>
         <ng-container *ngSwitchCase="dialogTypeConst.DELETE_STUDY_CONFIRM">
            <ng-container *ngTemplateOutlet="deleteStudyConfirm"></ng-container>
         </ng-container>
         <ng-container *ngSwitchCase="dialogTypeConst.TWO_FACTOR_CANCELLATION">
            <ng-container *ngTemplateOutlet="confirmTwoFactorCancellation"></ng-container>
         </ng-container>
         <ng-container *ngSwitchCase="dialogTypeConst.REVOKE_SHARE_CONFIRM">
            <ng-container *ngTemplateOutlet="revokeShareConfirm"></ng-container>
         </ng-container>         
      </ng-container>
   </mat-dialog-content>
   <mat-dialog-actions class="dialog-footer" *ngIf="hasActions">
      <button *ngIf="firstAction" class="btn btn-primary orange btn-dialog" (click)="firstActionClick()" id="firstActionId">
         {{firstAction}}
      </button>
      <button *ngIf="secondAction" class="btn btn-primary orange btn-dialog" (click)="secondActionClick()" id="secondActionId">
         {{secondAction}}
      </button>
      <button *ngIf="thirdAction" class="btn btn-primary orange btn-dialog" (click)="thirdActionClick()">
         {{thirdAction}}
      </button>
   </mat-dialog-actions>
</div>

<ng-template #addEvent>
   <div class="input-cf-event">
      <span translate="STUDY_DATA_CONFIRM_EVENT.EVENT_START"></span>
      <input class="form-control input-md" disabled [(ngModel)]="startTime"
      value="{{startTime}}">
   </div>
   <div class="input-cf-event">
      <span translate="STUDY_DATA_CONFIRM_EVENT.EVENT_DURATION"></span>
      <input class="form-control input-md"
      (change)="durationChange($event)" value="{{durationDisplay}}">
  </div>
  <div>
      <select class="form-control input-md si-select"
              [(ngModel)]="eventType" (change)="onChangeEventType($event);">
         <option *ngFor="let e of eventTypesAndNames" [value]="e.value">
              {{e.name}}
         </option>
      </select>
   </div>
</ng-template>

<ng-template #changeEvent>
   <div class="input-cf-event">
      <span translate="STUDY_DATA_CONFIRM_EVENT.EVENT_START"></span>
      <input class="form-control input-md" disabled [(ngModel)]="startTime"
      value="{{startTime}}">
   </div>
   <div class="input-cf-event">
      <span translate="STUDY_DATA_CONFIRM_EVENT.EVENT_DURATION"></span>
      <input class="form-control input-md"
      (change)="durationChange($event)" value="{{durationDisplay}}">
  </div>
  <div>
      <select class="form-control input-md si-select"
              [(ngModel)]="eventType" (change)="onChangeEventType($event);">
         <option *ngFor="let e of eventTypesAndNames" [value]="e.value">
              {{e.name}}
         </option>
      </select>
   </div>
</ng-template>

<ng-template #recalculate>
   <div id="recalcTxt1"
      [innerHTML]="'EVENT_DIALOG_TYPE.RECALC_TEXT1'|translate:{
         start: startTime,
         end: endTime
      }">
   </div>
   <div class="spacer-10"></div>
   <div id="recalcTxt2"
      [innerHTML]="'EVENT_DIALOG_TYPE.RECALC_TEXT2'|translate">
   </div>
   <div class="spacer-10"></div>
</ng-template>

<ng-template #reset>
   <div id="resetTxt1"
      [innerHTML]="'EVENT_DIALOG_TYPE.RESET_TEXT1'|translate">
   </div>
   <div class="spacer-10"></div>
   <div id="resetTxt2"
      [innerHTML]="'EVENT_DIALOG_TYPE.RESET_TEXT2'|translate">
   </div>
   <div class="spacer-10"></div>
</ng-template>

<ng-template #waitRecalculate>
   <div id="waitRecalcTxt1"
      [innerHTML]="'EVENT_DIALOG_TYPE.WAIT_RECALC_TEXT1'|translate:{
         start: startTime,
         end: endTime
      }">
   </div>
   <div class="spacer-10"></div>
   <div id="waitRecalcTxt2"
      [innerHTML]="'EVENT_DIALOG_TYPE.WAIT_RECALC_TEXT2'|translate">
   </div>
   <div class="spacer-10"></div>
</ng-template>

<ng-template #waitReset>
   <div id="waitResetTxt1"
      [innerHTML]="'EVENT_DIALOG_TYPE.WAIT_RESET_TEXT1'|translate">
   </div>
   <div class="spacer-10"></div>
   <div id="waitResetTxt2"
      [innerHTML]="'EVENT_DIALOG_TYPE.WAIT_RESET_TEXT2'|translate">
   </div>
   <div class="spacer-10"></div>
</ng-template>

<ng-template #errorDialog>
   <!-- <img class="float-right" [src]="errorImage" alt="Error image" width="300" height="300"> -->
   <div class="spacer-10"></div>
   <div class="error-message">
      {{errorMessage}}
      <br><br>
      {{code}}{{message}}
   </div>
   <div class="spacer-10"></div>
</ng-template>

<ng-template #generatingReportDialog>
   <div class="row">
      <div class="col-1">
            <div class="spacer-10"></div>
            <svg width="36" height="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <g class="spinner_Wezc">
                     <circle cx="12" cy="2.5" r="1.5" opacity=".14" />
                     <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                     <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                     <circle cx="21.50" cy="12.00" r="1.5" opacity=".57" />
                     <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                     <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                     <circle cx="12" cy="21.5" r="1.5" />
                  </g>
            </svg>
      </div>
      <div class="col-10">
            <div class="error-message">
               {{message}}
            </div>
            <div class="spacer-11"></div>
      </div>
   </div>
</ng-template>

<ng-template #loadingStatus>
   <div class="spacer-10"></div>
   <div class="spinner-container">
      <mat-spinner class="custom-spinner"></mat-spinner>
   </div>
   <div class="loading-message">
      {{loadingMessage}}
   </div>
   <div class="spacer-10"></div>
</ng-template>

<ng-template #deleteNotAuthorized>
   <div class="spacer-10"></div>
   <div class="spinner-container">
      <mat-spinner class="custom-spinner"></mat-spinner>
   </div>
   <div class="loading-message">
      {{loadingMessage}}
   </div>
   <div class="spacer-10"></div>
</ng-template>

<ng-template #deleteStudyConfirm>
   <div class="spacer-10"></div>
   <div class="spinner-container">
      <mat-spinner class="custom-spinner"></mat-spinner>
   </div>
   <div class="loading-message">
      {{loadingMessage}}
   </div>
   <div class="spacer-10"></div>
</ng-template>

<ng-template #confirmTwoFactorCancellation>
   <div class="spacer-10"></div>
   <div class="loading-message">
      {{message}}
   </div>
   <div class="spacer-10"></div>
</ng-template>

<ng-template #revokeShareConfirm>
   <!-- this is for ADD SHARE patient -->
   <div class="patient-wrap" *ngIf="!patientDetail">
      {{'STUDY_DATA_REPORT.PATIENT_NAME'|translate}}:
      <span class="patient-text">
         <span class="detail-wrap">{{patientName}}</span>
      </span>
      (<span class="patient-text detail-wrap">{{dob}}</span>)
      <div class="spacer-10"></div>
      {{'STUDY_DATA_REPORT.PATIENT_NUMBER'|translate}}:
      <span class="patient-text detail-wrap">{{patientNumber}}</span>
      <div class="spacer-10"></div>
      {{message}}
   </div>
   <!-- this is for REVOKE when the user is the owner -->
   <div class="patient-wrap" *ngIf="patientDetail && !patientIsRecipient">
      <span
         [innerHTML]="'PATIENT_SHARE.SHARE_DETAIL'|translate:
              {patientUId: patientUId,
              clinicName: clinicName,
              clinicAdminEmail: clinicAdminEmail}"
      ></span>
   </div>
   <!-- this is for REVOKE when the user is the recipient -->
   <div class="patient-wrap" *ngIf="patientDetail && patientIsRecipient">
      <span
         [innerHTML]="'PATIENT_SHARE.REVOKE_DETAIL'|translate:
              {patientUId: patientUId}"
      ></span>
      <div class="spacer-10"></div>
      <span class="patient-text detail-wrap">{{clinicName}}</span>
      <div class="spacer-10"></div>
      <span class="patient-text detail-wrap">{{clinicAdminEmail}}</span>
   </div>
   <div class="spacer-10"></div>
</ng-template>