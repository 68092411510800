import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * Must be abstract for use cases where user of the mixin extends an abstract class.
 */
type AbstractConstructor = abstract new (...args: any[]) => {};

export function WithUnsubscribe<T extends AbstractConstructor>(
    Base: T = class
    {} as any
)
{
  @Directive()
    abstract class UnsubscribeMixinClass extends Base implements OnDestroy
  {
      subscriptions: Subscription = new Subscription();

      /**
       * @override
       */
      ngOnDestroy(): void
      {
          this.subscriptions.unsubscribe();
      }
  }
  return UnsubscribeMixinClass;
}
