import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/shared/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SecureRoutingModule } from '@app/secure/secureRouting.module';
import { SecureLayoutComponent }
    from '@app/secure/core/layout/secureLayout.component';
import { HeaderSecureComponent }
    from '@app/secure/core/layout/headerSecure.component';
import { FooterSecureComponent } from './core/layout/footerSecure.component';
import { NavigationComponent }
    from '@app/secure/core/navigation/navigation.component';
import { AuthInterceptorProvider } from '@app/secure/helper/auth.interceptor';
import { ErrorInterceptorProvider } from '@app/secure/helper/error.interceptor';
import { IdleTimeoutComponent } from './core/timeout/idleTimeout.component';

@NgModule
({
    imports: 
    [
        CommonModule, 
        MaterialModule, 
        SecureRoutingModule, 
        TranslateModule.forChild(),
        NgIdleKeepaliveModule.forRoot()        
    ],
    declarations: 
    [
        SecureLayoutComponent,
        HeaderSecureComponent,
        NavigationComponent,
        IdleTimeoutComponent,
        FooterSecureComponent   
    ],
    providers: 
    [
        AuthInterceptorProvider, 
        ErrorInterceptorProvider
    ]
})
export class SecureModule { }
