import { Component, OnInit } from '@angular/core';

@Component
({
    selector: 'si-page-notFound',
    templateUrl: './pageNotFound.component.html',
    styleUrls: ['./pageNotFound.component.scss'],
})
class PageNotFoundComponent implements OnInit
{
    constructor()
    {}

    ngOnInit()
    {}
}
export { PageNotFoundComponent }