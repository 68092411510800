import { Component } from '@angular/core';

@Component
({
    selector: 'si-footer-secure',
    templateUrl: './footerSecure.component.html',
    styleUrls: ['./footerSecure.component.scss']
})

class FooterSecureComponent 
{
    currentYear: number = new Date().getFullYear();
}

export { FooterSecureComponent };
