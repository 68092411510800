import { Injectable } from '@angular/core';
import {
    CanLoad,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Route,
    Router,
} from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from '@app/secure/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad
{
    constructor(private auth: AuthService, private router: Router)
    {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean
    {
        return true;
    //    return this.auth.isLoggedIn();
    }

    canLoad(route: Route): Observable<boolean> | boolean
    {
        return true;
    //    return this.auth.isLoggedIn();
    }
}
