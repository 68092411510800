import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'si-footer',
    templateUrl: './siFooter.component.html',
    styleUrls: ['./siFooter.component.scss'],
})
export class SiFooterComponent implements OnInit
{
    currentYear = new Date().getFullYear();
    constructor()
    {}

    ngOnInit(): void
    {}
}
