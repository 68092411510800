import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SecureModule } from '@app/secure/secure.module';
import { LANGUAGE_TO_LOCALE_CONST } from '@app/constants/global';
import { SITranslateService } from '@app/shared/services/siTranslate.service'
import { EndUserRoleService } from '@service/core/endUserRole.service';
import { EndUserService } from '@service/core/endUser.service';

@Injectable
({
    providedIn: 'root'
})
class EndUserLocaleService 
{
    myLocale;
    
    constructor
    (
        private siTranslateService: SITranslateService,
        private endUserRoleService: EndUserRoleService,
        private endUserService: EndUserService
    ) 
    { 
        this.myLocale = null;
    }
    
    public getMyLocale()
    {
        let locale;
        let role;
        let currentLanguage;
        
        role = this.endUserService.getRole();
        
        if (!this.myLocale)
        {
            if (!this.endUserRoleService.isCustomerRole(role))
            {
                this.myLocale =  this.siTranslateService.getLanguage();   
            }
            else
            {
                currentLanguage = this.siTranslateService.getLanguage(); 
                locale = LANGUAGE_TO_LOCALE_CONST[currentLanguage];
                
                // found a locale to use first in the list 
                if (locale)
                {
                    // always default to english as second option
                    this.myLocale = [locale, 'en'];

                }
                // didn't find a locale, so default to English
                else
                {
                    this.myLocale = 'en';
                }                
            }            
        }
        
        return this.myLocale;
    }
        
}
export { EndUserLocaleService }