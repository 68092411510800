/* These are all the APIs on the server */

const API_END_USER =
{
    LOGIN_WEB_APP: '/api/login/webApp',
    LOGIN_WEB_APP_ACCESS: '/api/login/webAppAccess',
    WHO_AM_I: 'api/endUser/whoami',
    VERIFY_END_USER: 'api/endUser/verifyEndUser',
    GET_MY_MEMBERSHIP_INFO: 'api/endUser/getMyMembershipInfo',
    GET_AVAILABLE_MEMBERSHIPS: 'api/endUser/getAvailableMemberships',
    // membership
    GET_MY_MEMBERSHIP_TYPE: 'api/endUser/getMyMembershipType',
    GET_SETTING_MEMBERSHIP: 'api/siSetting/getSettingMembership',

    // subscription
    GET_MY_SUBSCRIPTION_INFO: 'api/endUser/getMySubscriptionInfo',

    GET_MY_USERNAME: 'api/endUser/getMyUsername',
    UPDATE_MY_USERNAME: 'api/endUser/updateMyUsername',
    UPDATE_MY_PASSWORD: 'api/endUser/updateMyPassword',
    GET_MY_TIMEZONE: 'api/endUser/getMyTimezone',
    GET_MY_CUSTOMER_NAME: 'api/customer/getMyCustomerName',
    GET_MY_LOGO: 'api/organization/getMyLogo',
    //in js is GENERATE_TWO_FACTOR_SETTING_CODE
    GENERATE_TWO_FACTOR_CODE: 'api/endUser/generateTwoFactorCode',
    IS_EMAIL_AVAILABLE_OR_INTERPRETING_CLINICIAN:
        'api/enduser/isEmailAvailableOrInterpretingClinician',
        // two factor authentication
    GET_TWO_FACTOR_SETTING: 'api/endUser/getTwoFactorSetting',
    ADD_TWO_FACTOR_SETTING: 'api/endUser/addTwoFactorSetting',
    TWO_FACTOR_SETTING_STATUS_CHANGE: 'api/endUser/twoFactorSettingStatusChange',
    GENERATE_TWO_FACTOR_SETTING_CODE: 'api/endUser/generateTwoFactorSettingCode',
    VERIFY_CODE: 'api/endUser/verifyCode',
    VERIFY_ENDUSER: 'api/endUser/verifyEndUser',
};

/*  from js:
    // membership
    GET_MY_MEMBERSHIP_INFO: 'endUser/getMyMembershipInfo',
    GET_MY_MEMBERSHIP_TYPE: 'endUser/getMyMembershipType',
    GET_SETTING_MEMBERSHIP: 'siSetting/getSettingMembership',

    // subscription
    GET_MY_SUBSCRIPTION_INFO: 'endUser/getMySubscriptionInfo',

    // si settings
    GET_STUDY_CSV_COLUMNS: 'siSetting/getStudyCsvCols',

    // login and creditials
    VERIFY_ENDUSER: 'endUser/verifyEndUser',
    IS_LOGGED_IN: 'endUser/isLoggedIn',

    // endUser specific info
    GET_MY_USERNAME: 'endUser/getMyUsername',
    GET_MY_TIMEZONE: 'endUser/getMyTimezone',

    // update some account settings info
    UPDATE_MY_USERNAME: 'endUser/updateMyUsername',
    UPDATE_MY_PASSWORD: 'endUser/updateMyPassword',

    // two factor authentication
    GET_TWO_FACTOR_SETTING: 'endUser/getTwoFactorSetting',
    ADD_TWO_FACTOR_SETTING: 'endUser/addTwoFactorSetting',
    TWO_FACTOR_SETTING_STATUS_CHANGE: 'endUser/twoFactorSettingStatusChange',
    GENERATE_TWO_FACTOR_SETTING_CODE: 'endUser/generateTwoFactorSettingCode',
    GENERATE_TWO_FACTOR_CODE: 'endUser/generateTwoFactorCode',

    // recorder info
    GET_DEVICES_FOR_CUSTOMER: 'device/getDevicesForCustomer',

    // Check if Email exist or is binded to IC
    IS_EMAIL_AVAILABLE_OR_INTERPRETING_CLINICIAN:
            'enduser/isEmailAvailableOrInterpretingClinician'

*/

const API_PATIENT =
{
    GET_PATIENT_LIST: 'api/patient/getMyPatients', // based on role
    ADD_PATIENT: 'api/patient/addPatient',
    UPDATE_PATIENT: 'api/patient/updatePatient',
    GET_PATIENT: 'api/patient/getPatient',
    // patient setting
    GET_PATIENT_SETTING: 'api/patient/getPatientSetting',
    GET_PATIENT_SHARE_OWNER: 'api/patient/getPatientShareOwner',
    SHARE_PATIENT: 'api/patient/sharePatient',
    REVOKE_SHARE: 'api/patient/revokeShare',
    GET_AUTO_SHARE_EMAIL: 'api/patient/getAutoShareEmail',
    UPDATE_AUTO_SHARE_EMAIL: 'api/patient/updateAutoShareEmail',
    VALIDATE_SHARE: 'api/patient/validateShare',
    GET_ALL_PATIENT_SHARE_TO: 'api/patient/getAllPatientShareTo',
    GET_PATIENT_SHARE_FROM: 'api/patient/getPatientShareFrom',
    INACTIVATE_PATIENT: 'api/patient/inactivatePatient',
    GET_LAST_UPLOAD: 'api/upload/getLastUpload',
    PROCESS_SLEEP_DATA: 'api/upload/processSleepData',
    CREATE_SLEEP_FILE: 'api/upload/createSleepFile',
    STREAM_SLEEP_DATA: 'upload/streamSleepData',
    GET_UPLOAD_KEY: 'api/upload/getUploadKey',
    ADD_INVALID_UPLOAD: 'api/upload/addInvalidUpload',
    GET_PROCESSING_STEP: 'api/upload/getProcessingStep',
    GET_PENDING_RECORDINGS: 'api/upload/getPendingRecordings',
    GET_USER_UPLOAD: 'api/upload/getUserUpload',
    GET_RECORDINGS: 'api/upload/getRecordings',
    GENERATE_STUDY_RECORDS_FOR_PENDING :
        'api/upload/generateStudyRecordsForPending',
    GENERATE_STUDY_RECORDS: 'api/upload/generateStudyRecords',
    GET_ELIGIBLE_CLINICIANS: 'api/clinician/getEligibleClinicians',
    ASSIGN_CLINICIAN: 'api/clinician/assignClinician',
    IS_DEVICE_ALLOWED_FOR_PROCESSING: 'api/device/isAllowedForProcessing'
};

const API_CLINICIAN =
{
    GET_CLINICIAN_LIST: 'api/clinician/getMyClinicians',
    UPDATE_MY_PROFILE: 'api/clinician/updateMyProfile',
    GET_MY_PROFILE: 'api/clinician/getMyProfile'
};

const API_CUSTOMER =
{
    GET_CUSTOMER_LIST: 'api/customer/getCustomers',
    GET_MY_CUSTOMER_SETTINGS: 'api/customer/getMyCustomerSettings',
    UPDATE_MY_CUSTOMER_SETTINGS: 'api/customer/updateMyCustomerSettings',
};

const API_ACTIVITY =
{
    GET_ACTIVITIES_LIST: 'api/activityReport/getActivities',
    GET_CSV_FOR_ACTIVITIES: 'api/activityReport/getCSVForActivities'
};

const API_RECORDER =
{
    GET_RECORDER_LIST: 'api/device/getDevicesForCustomer',
};

const API_STUDY =
{
    // study information and data
    GET_STUDIES: 'api/study/getStudies',
    GET_STUDY_LOCATION: 'api/studyData/getStudyDataLocation',
    GET_CUSTOMER_LOCATION: 'api/customer/getMyCustomerLocation',
    GET_REPORT_DATA: 'api/studyData/getReportData',
    GET_DECISION_ASSIST_DATA: 'api/studyData/getDecisionAssistData',
    GET_STUDY_DISPLAY_INFO: 'api/studyData/getStudyDisplayInfo',
    GET_SIGNAL_QUALITY_DATA: 'api/studyData/getSignalQualityData',
    // study data used for graphs
    GET_TRENDS_DATA: 'api/studyData/getTrendsChartData',
    GET_STUDY_NOTES: 'api/studyData/getStudyNotes',
    GET_STUDY_NOTE_TAGS: 'api/studyData/getStudyNoteTags',

    GET_PDF: 'api/studyData/toPdf',
    GET_STUDIES_CSV: 'api/studyData/allStudiesToCsv',
    GET_STUDY_CSV: 'api/studyData/studyToCsv',
    GET_DOCX_THREE_PERCENT: 'api/studyData/getDocxThreePercent',
    GET_DOCX_FOUR_PERCENT: 'api/studyData/getDocxFourPercent',
    GET_HRV_REPORT_PDF: 'api/study/getHrvReport',
    GET_HRV_REPORT_PERMISSION: 'api/study/hrvReportPermission',

    GET_SPECTROGRAM_DATA: 'api/studyData/getSpectrogramData',
    GET_ELFC_DATA: 'api/studyData/getELfcData',
    GET_SLEEPNOGRAM_DATA: 'api/studyData/getSleepnogramData',
    GET_TRACE_DATA: 'api/studyData/getTraceData',
    TRACE_DATA: 'api/studyData/traceData',
    GET_TRACE_EVENTS: 'api/studyData/getTraceEvents',
    PUBLISH_STUDY_NOTE: 'api/studyData/publishStudyNote',
    STUDY_FILES_TO_STORAGE: 'api/studyData/studyFilesToStorage',
    SEND_EMAIL_FOR_NOTE: 'api/studyData/sendEmailForNote',
    GET_REPORT_PERIODS: 'api/studyData/getReportPeriods',
    HRV_DATA: 'api/studyData/hrvData',
    GET_CVHR_EVENT_DATA: 'api/studyData/getCvhrEventData',
    GET_DESAT_DATA: 'api/studyData/getDesatData',
    GET_EDF: 'api/studyData/getEdf',
    RECALCULATE_STUDY: 'api/study/recalculateStudy',
    HRV_REPORT_PERMISSION: 'api/study/hrvReportPermission',
//    GET_MY_CUSTOMER_NAME: 'api/customer/getMyCustomerName',
//    GET_MY_LOGO: 'api/organization/getMyLogo',
//    GET_MY_TIMEZONE: 'api/endUser/getMyTimezone',
    GET_PATIENT_SHARE_OWNER: 'api/patient/getPatientShareOwner',
    GET_SNORE_EVENT_DATA:'api/studyData/getSnoreEventData',
    GET_BODY_POS_DATA: 'api/studyData/getTraceData',
    GET_ACT_EPOCH_DATA: 'api/studyData/getTraceData',
    GET_SPO2_DATA: 'api/studyData/getTraceData',
    GET_DESAT_EVENT_DATA: 'api/studyData/getDesatData',

    REQUEST_INTERPRETATION: 'api/clinician/requestInterpretation',
};

const URL_ADMIN =
{
    GET_MY_PROFILE: 'admin/getMyProfile',
    UPDATE_MY_PROFILE: 'admin/updateMyProfile',

    // clinician info
    GET_CLINICIAN_LIST: 'api/clinician/getMyClinicians',
    ADD_CLINICIAN: 'api/clinician/registerClinician',
    GET_CLINICIAN: 'api/clinician/getClinician',
    UPDATE_CLINICIAN: 'api/clinician/updateClinician'
};

export
{
    API_END_USER,
    API_PATIENT, API_CLINICIAN,
    API_CUSTOMER, API_ACTIVITY,
    API_RECORDER, API_STUDY, URL_ADMIN
}
