import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'si-public-layout',
    templateUrl: './publicLayout.component.html',
    styleUrls: ['./publicLayout.component.scss'],
})
export class PublicLayoutComponent implements OnInit
{
    noHeader = false;
    constructor(private router: Router)
    {}

    ngOnInit()
    {
        if (this.router.url === '/login')
        {
            this.noHeader = true;
        }
    }
}
