import {
    Component,
    OnInit,
    Inject,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { Idle } from '@ng-idle/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'idle-timeout-modal',
    templateUrl: './idleTimeout.component.html',
    styleUrls: ['./idleTimeout.component.scss'],
})
export class IdleTimeoutComponent implements OnInit
{
    private idle: Idle;
    public countdown: number;

    //Need this in order to close the dialog box on idle end.
    // This is because, for some reason,
    //I cannot do a this.dialogRef.close() in the onIdleEnd subscription.
    @ViewChild('closeMeDiv') closeMeDiv: ElementRef;

    constructor(
        //Data is: {"idleObj":<idle object>,
        //"timeout":<timeoutPeriodSec (integer)>}
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<IdleTimeoutComponent>
    )
    {
        this.idle = data.idleObj;
        this.countdown = data.timeout;
    }

    ngOnInit()
    {
        this.idle.onTimeoutWarning.subscribe((countdown: number) =>
        {
            this.countdown = countdown;
        });
        this.idle.onIdleEnd.subscribe(() =>
        {
            this.closeMeDiv.nativeElement.click();
        });
    }

    closeMe()
    {
        this.dialogRef.close();
    }
}
