/* These constants must match the server-side constants */
export const ROLE_TYPE = 
{
    NONE: 0,
    SI_ADMIN: 1,
    ADMIN: 2,
    CLINICIAN: 3,
    SI_MANAGER: 4,
    DISTRIBUTOR: 5,
    INTERPRETING_CLINICIAN: 6
};

export const GROUP_CONST = 
{
    GROUP_EIGHT: 8,
    GROUP_NINE: 9,
};

// date formats used
export const DATE_FORMAT_CONST =  
{
    // deprecated: Use TIME_ONLY_LOCALE instead with locale set
    TIME_ONLY: 'hh:mm a',
    // deprecated: Use DATE_ONLY_LOCALE instead with locale set
    DATE_ONLY: 'MMM DD, YYYY',

    // Locale time formats
    TIME_ONLY_LOCALE: 'LT',  // ie: 9:00 AM
    TIME_ONLY_SECONDS_LOCALE: 'LTS',  // ie: 9:00:00 AM

    // Locale date formats
    DATE_ONLY_LOCALE: 'll',  // ie: Jan 31, 2020
    DATE_ONLY_NUMERAL_LOCALE: 'L',  // ie: 01/31/2020, 2020-01-31

    // Locale date and time formats
    DATE_TIME_LOCALE: 'lll', // ie: Jan 31, 2020 9:00 AM

    // NOT locale, but needed in some instances like graphs or csv generation
    TIME_ONLY_SECONDS_MILITARY: 'HH:mm:ss',
    TIME_ONLY_MILITARY: 'HH:mm',

    // format from and to server
    DATE_ONLY_SQL: 'YYYY-MM-DD',
    DATE_TIME_SQL: 'YYYY-MM-DD HH:mm:ss',

    // date tag added to filename
    DATE_TIME_FILENAME: 'YYYYMMDDTHHmm',

    // date time tag added to filename
    DATE_TIME_SEC_FILENAME_DASH: 'YYYYMMDD-HHmmss',

    // date tag added to filename that includes seconds
    DATE_TIME_SEC_FILENAME: 'YYYYMMDDTHHmmss',
    DATE_SQL: 'YYYY-MM-DD HH:mm:ss'
};

/*
 * These are messages that are entered by each form fields during errors
 * or notifications.
 */
export const FORM_VALIDATION_MESSAGE = 
{
    REQUIRED: 'Required',
    PASSWORD_CRITERIA: 'Passwords must be a minimum of eight characters and '+
                       'include at least one uppercase and a number or a symbol.',
    PASSWORD_NOT_CONFIRMED: 'Passwords do not match!',
    CURRENT_PASSWORD_ERROR: 'Please enter the correct Current Password.',
    USERNAME_DUPLICATE: 'Username is already taken.  Please use a different one.',
    EMAIL_FORMAT_INVALID: 'Please enter a valid email.'
};

/*
   * These are messages related to the whole form, usually once submitted.
   */
export const FORM_MESSAGE = 
{
    UPDATE_USERNAME_SUCCESSFUL: 'Username successfully updated.',
    UPDATE_USERNAME_FAILED: 'Unable to update username.',

    UPDATE_PASSWORD_SUCCESSFUL: 'Password successfully updated.',
    UPDATE_PASSWORD_FAILED: 'Unable to update password.'
};

/*
   * These are messages related to lists of information (ie: agGrid)
   */
export const LIST_MESSAGE = 
{
    PATIENT_LIST_SELECTION_ERROR: 'Please select a patient from the list.',
    CLINICIAN_LIST_SELECTION_ERROR: 'Please select a clinician from the list.',
    RECORDER_LIST_SELECTION_ERROR: 'Please select a recorder from the list.'
};

export const GRID = 
{
    ROW_HEIGHT: 38,
    FONT_SIZE: '28px',
}

// imported form siWebClientJs


// Translation language keys available
export const LANGUAGE_KEY_CONST = 
{
  ENGLISH_US: 'en',
  CHINESE: 'cn'
};
// link translation language key to moment locale key
export const LANGUAGE_TO_LOCALE_CONST = 
{
  'cn': 'zh-cn',
  'en': 'en'
};

// General membership, roles, and status
export const MEMBERSHIP_TYPE_CONST =  
{
    NONE    : 0,
    DEVICE  : 1,
    PATIENT : 2,
    DEMO    : 3,
    DIRECT_BILLING : 4,
    CREDIT : 5
};
export const MEMBERSHIP_NEEDS_STORE_ARRY_CONST =  [0, 1, 2, 5];
export const CREDIT_TYPE_CONST =  
{
    NONE : 0,
    ADD_CREDIT : 1,
    APPLY_UPLOAD : 2,
    APPLY_ACCESS : 3,
    SUBTRACT_CREDIT : 4,
};
export const ROLE_TYPE_CONST =  
{
    SI_ADMIN   : 1,
    ADMIN      : 2,
    CLINICIAN  : 3,
    SI_MANAGER : 4,
    DISTRIBUTOR: 5,
    INTERPRETING_CLINICIAN: 6
};
export const ROLE_GROUP_CUSTOMER_USERS_CONST = [2, 3];
export const END_USER_STATUS_CONST =  
{
    INACTIVE : 0,
    ACTIVE   : 1
};

// Measurement system
export const MEASUREMENT_SYS_CONST =  
{
    METRIC  : 1,
    US      : 2
};

// Gender constants used for data from/to server
export const GENDER_CONST =  
{
    MALE     : 'M',
    FEMALE: 'F',
    OTHER: 'O'
};

// Customer Settings
export const CUSTOMER_SETTING_NAME_CONST = 
{
    VIEW_ALL_PATIENTS: 'viewAllPatients',
    SLEEPIMAGE_API: 'sleepImageAPI'
};


