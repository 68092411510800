/*
 * MOVE IT TO auth.service.ts
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccessData } from './AccessData.interface';
//import { LoginData } from './LoginData.interface';
import { API_END_USER } from '@app/constants/apis';
import { environment } from '@env/environment';

@Injectable
({
    providedIn: 'root',
})
class LoginService
{
    
    /**
     * @desc   - constructor for the service
     * @access - public
     * @param  - http : manages API call
     * @return - 
     */
    constructor
    (
        private http: HttpClient
    )
    {}

    /**
     * @desc   - process user login from the login screen
     * @access - public
     * @param  - LoginData loginData : login data
     * @return - Observable httpRequest : observable object to process login
     */
    login
    (
        username,
        password
    )
    {
        let httpRequest;

        let session =
        {
            uId: null,
            firstName: null,
            lastName: null,
            role: null,
            isEmulate: null,
            emulateUId: null,
            refreshToken: null,
            accessToken: null,
            mfaPassed: null
        };

        httpRequest = this.http.post<any>
        (
            `${environment.apiURL}${API_END_USER.LOGIN_WEB_APP}`,
            {
                username: username,
                password: password,
            }
        )
        .pipe(map((response) =>
        {
            session.refreshToken = response.siData.refreshToken;
            session.accessToken = response.siData.accessToken;
            session.uId = response.siData.uId;
            session.role = response.siData.role;
            session.mfaPassed = response.siData.mfaPassed;
            
//            this.sessionSubject = new BehaviorSubject<any>(session);

            return session;
        }));

        return httpRequest;
    }

    /**
     * @desc   - process user access based on access data
     * @access - public
     * @param  - accessData accessData : access data
     * @return - Observable httpRequest : observable object to process login
     */
    access
    (
        accessData: AccessData
    )
    {
        let httpRequest;

        let session =
        {
            uId: null,
            firstName: null,
            lastName: null,
            role: null,
            isEmulate: null,
            emulateUId: null,
            refreshToken: null,
            accessToken: null,
            mfaPassed: null            
        };

        httpRequest = this.http.post<any>
        (
            `${environment.apiURL}${API_END_USER.LOGIN_WEB_APP_ACCESS}`,
            {
                accessKey: accessData.accessKey,
                username: accessData.username,
            }
        )
        .pipe(map((response) =>
        {
            session.refreshToken = response.siData.refreshToken;
            session.accessToken = response.siData.accessToken;
            session.uId = response.siData.uId;
            session.role = response.siData.role;
            session.mfaPassed = response.siData.mfaPassed;

            return session;
        }));

        return httpRequest;
    }
}

export { LoginService };
