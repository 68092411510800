// Basic auth interceptor adds refresh token to the request
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { AuthService } from '@app/secure/auth/auth.service';

@Injectable()
class BasicAuthInterceptor implements HttpInterceptor
{
    constructor
    (
        private authService: AuthService
    )
    {}

    intercept
    (
        request: HttpRequest<any>, 
        next: HttpHandler
    ): Observable<any>
    {
        let header: any;
        let isLoggedIn;
        let isFormData;
        
        isLoggedIn = this.authService.checkToken();
        isFormData = request.body instanceof FormData;
        
        if (isLoggedIn)
        {
            header = this.authService.getAuthorizationHeader(isFormData);
            request = request.clone({ setHeaders: header });
        }
        return next.handle(request);
    }
}

export { BasicAuthInterceptor };
/**
 * Provider Plain old Javascript Object (POJO) for the interceptor
 */
export const AuthInterceptorProvider = 
{
    provide: HTTP_INTERCEPTORS,
    useClass: BasicAuthInterceptor,
    multi: true,
};
