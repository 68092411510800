<div #closeMeDiv (click)="closeMe()">
    <div mat-dialog-title>
        <h3>Please move the mouse or press any key</h3>
        <hr />
    </div>
    <div mat-dialog-content>
        <p>
            You'll be logged out in 
            <span class="idle-label idle-label-warning">{{countdown}}</span>
            second<span *ngIf="countdown != 1">s</span>.
        </p>
    </div>
</div>