// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const environment =
{
    production: false,

    productName: 'Corporate',
    productVersion: '9',

    emailSupport: 'mehran.majidi@sleepimage.com',
    storeURL: 'http://sleepcorporate.uldev.co/sso',
    publicWebsiteURL: 'http://www.sleepimage.com',
    //    apiURL : "http://api.sleepimage.com"
    apiURL : "https://devapi.sleepqualityindex.com"
    // apiURL : 'http://3.217.187.197:8002'
    // apiURL : 'http://localhost:4211'
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export { environment }
