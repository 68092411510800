import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

// Components & Routing
import { PublicRoutingModule } from '@app/public/publicRouting.module';
import { HeaderComponent } from '@app/public/layout/header.component';
import { PublicLayoutComponent } from '@app/public/layout/publicLayout.component';
import { SiFooterComponent } from '@app/public/layout/siFooter.component';
import { LoginComponent } from '@app/public/login/login.component';
import { MaterialModule } from '@app/shared/material.module';


@NgModule(
{
    imports:
    [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
        PublicRoutingModule
    ],
    declarations:
    [
        PublicLayoutComponent,
        HeaderComponent,
        LoginComponent,
        SiFooterComponent
    ],
    exports:
    [
        SiFooterComponent,
        HeaderComponent
    ]
})
export class PublicModule
{}
