import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import { ROLE_TYPE } from '@app/constants/global';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '@interface/core/menuItem.interface';

//import { AuthService } from '@app/secure/auth/auth.service';

@Injectable({
    // Service is part of top directory since public module uses it also
    providedIn: 'root',
})
export class NavigationService
{
    private adminMenuItems: MenuItem[];
    private clinicianMenuItems: MenuItem[];
    private clinicianMenuItemsJs: MenuItem[];
    private distributorMenuItems: MenuItem[];
    private systemAdminMenuItems: MenuItem[];
    private systemManagerMenuItems: MenuItem[];

    constructor
    (
        private router: Router,
        private translateService: TranslateService
    )
    {
        this.clinicianMenuItems = 
        [
            { 
                label: 'MAIN_MENU.PATIENTS', route: '/customer/patients' 
            },
//            { label: 'MAIN_MENU.RECORDERS', route: '/customer/recorders' },
            { 
                label: 'MAIN_MENU.PROFILE', 
                route: '/customer/profile/clinician' 
            },
            { 
                label: 'MAIN_MENU.SETTINGS', 
                route: '/customer/accountSetting' 
            },
            { 
                label: 'MAIN_MENU.HELP', 
                route: '/customer/help' 
            },
        ];

        this.adminMenuItems = 
        [
            { label: 'MAIN_MENU.PATIENTS', route: '/customer/patients' },
            { label: 'MAIN_MENU.CLINICIANS', route: '/customer/clinicians' },
            { label: 'MAIN_MENU.PROFILE', route: '/customer/profile/admin' },
            { label: 'MAIN_MENU.SETTINGS', route: '/customer/accountSetting' },
            { label: 'MAIN_MENU.HELP', route: '/customer/help' },
        ];

        this.distributorMenuItems = 
        [
            { label: 'Customers', route: '/distributor/customers' },
            { label: 'Reports', route: '/distributor/reportList' },
            { label: 'SIAdmin', route: '/distributor/siAdminList' },
            { label: 'Distributors', route: '/distributor/DistributorList' },
            { label: 'configuration', route: '/distributor/sysConfiguration' },
        ];

        this.systemAdminMenuItems = 
        [
            { label: 'Customers', route: '/si/customers' },
        ];

        this.systemManagerMenuItems = [
            { label: 'Customers', route: '/si/customers' },
            { label: 'Reports', route: '/si/reports' },
            { label: 'SIAdmins', route: '/si/siAdmins' },
            { label: 'Distributors', route: '/si/distributors' },
            /* NOT YET ADDED
      {label: 'SIAdmins', route: '/si/siAdmins'},
      {label: 'Distributors', route: '/si/distributors'},
      */
            { 
                label: this.translateService.instant('MAIN_MENU.CONFIGURATION'), 
                       route: '/si/sysConfig' 
            }
        ];
    }
        
    setActivePage()
    {
        let eventSubscription;
        
        eventSubscription = this.router.events.pipe
        (
            filter((event: Event): event is NavigationEnd => 
                    event instanceof NavigationEnd)
        )
        .subscribe(event => 
        {
            sessionStorage.setItem('activePage', event.url);
        });      
        
        return eventSubscription;
    }

    resetActivePage()
    {
        sessionStorage.setItem('activePage', '');    
    }
    
    getActivePage()
    {
        let activePage = sessionStorage.getItem('activePage');
        
        return activePage;
    }
    
    getMenuPerRole
    (
        role: number
    ): MenuItem[]
    {
        let menuItems: MenuItem[];

        switch (role)
        {
            case ROLE_TYPE.ADMIN:
                menuItems = this.adminMenuItems;
                break;
            case ROLE_TYPE.CLINICIAN:
                menuItems = this.clinicianMenuItems;
                break;
            case ROLE_TYPE.DISTRIBUTOR:
                menuItems = this.distributorMenuItems;
                break;
            case ROLE_TYPE.SI_ADMIN:
                menuItems = this.systemAdminMenuItems;
                break;
            case ROLE_TYPE.SI_MANAGER:
                menuItems = this.systemManagerMenuItems;
                break;
            default:
                menuItems = [];
        }

        return menuItems;
    }

    gotoActivePage
    (
        activePage
    )
    {
        this.router.navigate([activePage]);
    }
    
    
    /*
   * Goto the first route given the endUser role (ie: clinician, admin, etc.)
   * This is the first route that comes up when a user logs in with a given
   * role.
   */
    gotoFirstPage
    (
        role: number
    )
    {
        let firstPageUrl: string;

        switch (role)
        {
            case ROLE_TYPE.ADMIN:
                firstPageUrl = '/customer/patients';
                break;
            case ROLE_TYPE.CLINICIAN:
                firstPageUrl = '/customer/patients';
                break;
            case ROLE_TYPE.SI_ADMIN:
            case ROLE_TYPE.DISTRIBUTOR:
            case ROLE_TYPE.SI_MANAGER:
                firstPageUrl = '/si/customers';
                break;
            default:
                firstPageUrl = '/login';
        }

        this.router.navigate([firstPageUrl]);
    }

    gotoLoginInPage()
    {
        this.router.navigate(['login']);    
    }
    
    gotoSignInPage()
    {
        this.router.navigate(['securityCode']);
    }

    gotoEditPatient()
    {
        this.router.navigate(['/patients/detail']);
        // this.router.navigate(['/customer/patients/detail']);
    }

    gotoPatientStudiesDashboard()
    {
        this.router.navigate(['/customer/patientData']);
        //this.router.navigate(['/customer/patientStudies']);
    }

    gotoPatientList()
    {
        this.router.navigate(['/customer/patients']);
    }
}
