import
{
    MissingTranslationHandler, 
    TranslateService 
} from '@ngx-translate/core';

import { SITranslateService } from 
         '@app/shared/services/siTranslate.service';

class SITranslationHandler 
      implements MissingTranslationHandler 
{
    siTranslateService;
    
    constructor()
    {
        this.siTranslateService = SITranslateService.instance;
    }
    
    public handle 
    (
        params: any
    ) 
    {        
        let str: string | null = '';
        let arr1: string | null = '';
        let arr2: string | null = '';
        
        arr1 = this.siTranslateService.getItem('arr1');
        
        if (!arr1) 
        {
            arr1 = '{';
            this.siTranslateService.setItem('arr1', '{');
        }

        str = arr1;

        if (str && str.match('}')) 
        {
            str = str.replace(/}/, ',');
        }
        if (str && !str.includes(params.key)) 
        {
            str += '"'+params.key+'":"'+params.key+'"}';
        }
        if (str && str.match(',$')) 
        {
            str = str.replace(/,$/, '}');
        }

        if(str)
        {
            this.siTranslateService.setItem('arr1', str);
        }
        
        arr2 = this.siTranslateService.getItem('arr2');

        if (!arr2) 
        {
            arr2 = '{';
            this.siTranslateService.setItem('arr2', '{');
        }
        
        str = arr2;

        if (str && str.match('}')) 
        {
            str = str.replace(/}/, ',');
        }
        if (str && !str.includes(params.key)) 
        {
            str += '"'+params.key+'":"",';
        }
        if (str && str.match(',$')) {
            str = str.replace(/,$/, '}');
        }

        if (str)
        {
            this.siTranslateService.setItem('arr2', str);
        }
    }
}

export { SITranslationHandler }
