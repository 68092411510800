<div class="si-footer-container">
  <section class="footer-terms">
    <a target="_blank"
       href="https://www.sleepimage.com/wp-content/uploads/Terms-of-Use.pdf">
       Terms Of Use</a>
    <a target="_blank"
      href="https://www.sleepimage.com/wp-content/uploads/Privacy-Policy.pdf">
       Privacy Policy</a>
  </section>
  
  <section class="footer-notes">
      The SleepImage System Instructions for Use are provided solely 
      in an electronic format on our website at 
      <a href="http://www.sleepimage.com" target="_blank">www.sleepimage.com</a> 
      and in the Help tab of your account.
  </section>
  
  <section class="footer-info">
    <div>
      <img class="" src="/assets/images/manufacture.png"
        style="width:auto; height:16px; vertical-align:baseline;">
      <span>Manufactured by:</span>
      <span class="no-wrap">MyCardio, LLC.,</span> 
      <span class="no-wrap">3003 E 3rd Ave.,</span>
      <span class="no-wrap">Denver, CO 80206, USA.</span>
      <span class="no-wrap">&copy;{{currentYear}} All rights reserved.
      </span>
  
      <br>
      <span class="no-wrap">
        <span class="prescription-mark">&#8478;</span>
        This is a prescription product.
      </span>
      <span>*+B315SLEEPIMAGESYSTEM0/$$72.169*</span>
      <span class="no-wrap">
        <img src="/assets/images/ce-mark.png"
          style="width:auto; height:18px; margin-left:5px;"
          alt="CE "><span style="font-size:10px;">0413</span>
      </span>
      <br>
      <span class="no-wrap">
          <img src="/assets/images/instructionForUse.png"
               style="width:auto;height:18px; marging-left:5px;" 
               alt="MAN"><span style="font-size:10px">Attention, consult accompanying documentation</span>
      </span>
      <br>
      SleepImage&reg; is a registered trademark of MyCardio LLC.
    </div>
  
    <!-- DigiCert Seal HTML -->
    <!-- Place HTML on your site where the seal should appear -->
    <div>
      <div id="DigiCertClickID_ETcGQIyX"></div>
    </div>
  </section>
  
  <!-- DigiCert Seal Code -->
  <!-- Place with DigiCert Seal HTML or with other scripts -->
  <script type="text/javascript">
  var __dcid = __dcid || [];__dcid.push(["DigiCertClickID_ETcGQIyX", "15", "m", "black", "ETcGQIyX"]);(function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());
  </script>
</div>  
