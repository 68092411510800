import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PublicLayoutComponent } from '@app/public/layout/publicLayout.component';
import { LoginComponent } from '@app/public/login/login.component';

const publicRoutes: Routes = [
    // Empty paths should redirect to first page which is the Login page
    { path: '', redirectTo: 'login', pathMatch: 'full' },

    // login route uses the Public Layout
    {
        path: 'login',
        component: PublicLayoutComponent,
        children: [{ path: '', component: LoginComponent }],
    },

    // securityCode route uses the Public Layout
    {
        path: 'securityCode',
        component: PublicLayoutComponent,
        loadChildren: () =>
            import('./signIn/signIn.module').then((m) => m.SignInModule),
    },

    // Lazy-load the SignUp since it's not always used
    {
        path: 'signup',
        component: PublicLayoutComponent,
        loadChildren: () =>
            import('./signUp/signUp.module').then((m) => m.SignUpModule),
    },
];

@NgModule({
    imports: [RouterModule.forChild(publicRoutes)],
    exports: [RouterModule],
})
export class PublicRoutingModule
{}
