import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_END_USER } from '@app/constants/apis';
import { ErrorIntercept} from '@app/secure/helper/error.interceptor';
import { TokenAuth } from '@app/secure/auth/TokenAuth.model';
import { EndUser } from '@app/secure/core/endUser/EndUser.model';
import { SessionService } from '@service/core/session.service';
import { EndUserService } from '@service/core/endUser.service';
import { NavigationService } from '@service/core/navigation.service';

@Injectable
({
    providedIn: 'root',
})
class AuthService
{
    private tokenAuth: TokenAuth | null;

    constructor
    (
        private http: HttpClient,
        private router: Router,
        private injector: Injector,
        private sessionService: SessionService,
        private endUserService: EndUserService,
        private navigationService: NavigationService
    )
    { 
        let sessionData;
        
        sessionData = this.sessionService.getSession();
        if (sessionData)
        {
            this.setToken(sessionData.refreshToken, 
                          sessionData.accessToken);    
        }
    }
    
    // TODO : ALL ROLE CHECKS, NEEDS TO BE MOVED TO endUser.service
    // Check the roles from the logged in user
    public isAdmin(): boolean
    {
        return this.endUserService.isAdmin();
    }
    public isClinician(): boolean
    {
        return this.endUserService.isClinician();
    }
    public isSIAdmin(): boolean
    {
        return this.endUserService.isSIAdmin();
    }
    public isSIManager(): boolean
    {
        return this.endUserService.isSIManager();
    }
    public isDistributor(): boolean
    {
        return this.endUserService.isDistributor();
    }

    // Check combined roles for the logged in user
    public isCustomerUser(): boolean
    {
        return this.endUserService.isCustomerUser();
    }
    public isSIUser(): boolean
    {
        return this.endUserService.isSIUser();
    }
    
    public checkToken(): boolean
    {
        let hasToken;
        
        if (this.tokenAuth && 
            this.tokenAuth.accessToken)
        {
            hasToken = true;
        }
        else
        {
            hasToken = false;
        }
        
        return hasToken;
    }
    
    public isLoggedIn(): boolean
    {
        let loggedIn;
        let mfaPassed;
        
        mfaPassed = this.endUserService?.mfaPassed();
        
        if (mfaPassed && 
            this.tokenAuth && 
            this.tokenAuth.accessToken)
        {
            loggedIn = true;
        }
        else
        {
            loggedIn = false;
        }
        
        return loggedIn;
    }

    /**
     * set token
     *
     * @param  - string accessToken
     * @param  - string refreshToken
     * @return -
     */
    public setToken
    (
        refreshToken, 
        accessToken
    )
    {
        this.tokenAuth = new TokenAuth(refreshToken, accessToken);
    }

    /**
     * get refresh token
     *
     * @param  - 
     * @return - string refreshToken : refresh token
     */
    public getRefreshToken(): String
    {        
        return this.tokenAuth.refreshToken;
    }

    /**
     * get access token
     *
     * @param  - 
     * @return - string accessToken : access token
     */
    public getAccessToken(): String
    {
        return this.tokenAuth.accessToken;
    }
    
    /**
     * @desc   - get authorization header
     * @access - public
     * @param  - isFormData: true if form is begin submitted 
     * @return - object header: token header object for the 
     *                          web services
     */                
    public getAuthorizationHeader
    (
        isFormData
    ): Object 
    {
        let header;
        
        if (isFormData)
        {
            header = ({Accept: 'application/json',
                       Authorization: `Bearer ${ this.getAccessToken() }`});
        }
        else
        {
            header = ({Accept: 'application/json',
                               'Content-Type': 'application/json',
                       Authorization: `Bearer ${ this.getAccessToken() }`});
        }
        
        return header
    }
        
    public logout ()
    {
        let errorIntercept;
        
        this.sessionService.clearSession();
        errorIntercept = this.injector.get(ErrorIntercept);
        errorIntercept.destroy();
        this.navigationService.gotoLoginInPage();
        
        // reset tokens
        this.tokenAuth = null;
    }
}
export { AuthService };
