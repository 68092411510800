import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { TranslateLoader } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SITranslateService } from 
    '@app/shared/services/siTranslate.service';

class SITranslateLoader 
       implements TranslateLoader
{
    lang: string | null;     

    constructor
    (
        private readonly http: HttpClient,
        private siTranslateService : SITranslateService
    )
    {
        this.lang = this.siTranslateService.getLanguage();
    }

    /**
     * @desc
     * Gets translation
     * @param lang
     * @returns translation
     */
    getTranslation(lang: string): Observable<any>
    {
    // put translations files content together
    // for the translation to work
        return combineLatest([
            this.http.get(`./assets/i18n/admin/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/clinician/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/credit/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/dialog/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/help/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/login/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/main/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/patient/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/recorder/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/settings/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/studyData/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/customer/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/reports/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/pageTitle/${lang}.json`, 
            {
                responseType: 'json'
            }),
            this.http.get(`./assets/i18n/twoFactor/${lang}.json`, 
            {
                responseType: 'json'
            }),
        ])
        .pipe(map((data) =>
        {
            // 1. convert array of objects into nested object
            let mergedObject: any = 
                data.reduce((r, c) => Object.assign(r, c), {});
            
            // 2. flatten nested object
            let flatten: any = (obj = mergedObject, roots = [], sep = '.') =>
                Object
                // find props of given object
                .keys(obj)
                // return an object by iterating props
                .reduce((memo, prop) =>
                    Object.assign
                    (
                        // create a new object
                        {},
                        // include previously returned object
                        memo,
                        Object.prototype.toString.call(obj[prop]) ===
                            '[object Object]' ?
                            // keep working if value is an object
                            flatten(obj[prop], 
                                    this.concatize(roots, prop), sep) :
                            // include current prop and value 
                            // and prefix prop with the roots
                            { 
                                [this.concatize(roots, 
                                                prop).join(sep)]: obj[prop] 
                            }
                    ),
                    {}
                );
                    
                return flatten(mergedObject);
            })
        );
    }

    /**
     * @desc
     * Params overrides translate loader
     * @param roots
     * @param prop
     * @returns concatize
     */
    concatize(roots: any, prop: any): any[]
    {
        return roots.concat([prop]);
    }    
}

export { SITranslateLoader }