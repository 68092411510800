// Service Error Codes
const ERROR_CONST =
{
    NO_ERROR             : 0,
    GENERAL              : 1,
    GENERAL_DB           : 2,
    DB_CONNECTION        : 3,
    DB_ID_NOT_FOUND      : 4,
    DB_ITEM_NOT_FOUND    : 5,
    /* 6, 7 error codes reserved for future DB items */
    INVALID_SESSION_INFO : 8,
    INVALID_ROLE         : 9,
    INVALID_POST_DATA    : 10,
    PERMISSION_DENIED    : 11,
    TIMED_OUT            : 12,
    EMAIL_NOT_SENT       : 13,
    ITEM_NOT_ADDED       : 14,
    ITEM_NOT_UPDATED     : 15,
    ITEM_NOT_DELETED     : 16,
    PATIENT_NUMBER_TAKEN : 18,
    UID_TAKEN            : 19,
    USERNAME_TAKEN       : 20,
    EMAIL_TAKEN          : 21,
    NAME_TAKEN           : 22,
    DATA_FILE_NOT_FOUND  : 23,
    MISSING_PACKET       : 24,
    CORRUPT_PACKET       : 25,
    NO_MEMBERSHIP_CHANGE_FOR_PENDING_UPLOAD : 50,
    NO_PATIENT_INACTIVE_FOR_PENDING_UPLOAD : 51,
    NEED_CREDIT: 60,
    CREDIT_NOT_ADDED: 61,
    CREDIT_NOT_SUBTRACTED: 62,
    CREDIT_SUBTRACT_BELOW_ZERO: 63,
    DEVICE_NOT_IN_INVENTORY: 65,
    DEVICE_NOT_AVAILABLE: 66,
    DEVICE_ALREADY_ASSIGNED: 67,
    DEVICE_NOT_ASSIGNED_CUSTOMER: 68,
    SHARE_TO_OWNER: 200,
    SHARE_PATIENT_RECIPIENT_EMAIL_NOT_FOUND: 201,
    DUPLICATE_SHARE: 202,
    AUTO_SHARE_NOT_FOUND: 203,
    AUTO_SHARE_EMAIL_NOT_FOUND: 204,
    AUTO_SHARE_EMAIL_OWNER: 205,
    AUTO_SHARE_EMAIL_BLANK: 206,
    SQLITE_FILE_READ_FAILED: 510,

    // For admin profile service
    ADMIN_EMAIL_TAKEN: 30,
    ADMIN_CLINIC_NAME_TAKEN: 31,
};

const SI_UPLOAD_ERROR_CODE =
{
    'NO_ERROR': 0,
    'OUT_OF_SEQUENCE': 24,
    'CORRUPT_PACKET': 25

};

export { ERROR_CONST, SI_UPLOAD_ERROR_CODE }